import Types from "./types";

const INITIAL_STATE = {
  flag: {},
  loaded: false,
  settingFlag: false,
  errors: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FLAG_SUCCESS:
      return { ...state, settingFlag: false, errors: [] };
    case Types.FLAG_ERROR:
      return {
        ...state,
        loaded: true,
        errors: [...action.payload],
        settingFlag: false,
      };
    case Types.FLAG_REQUEST:
      return { ...state, settingFlag: true };
    default:
      return state;
  }
};
