import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { signOut } from '../../../auth/state/actions'
// @Material-UI
import { AppBar, Hidden, Button, Typography } from "@material-ui/core";
// Assets
import Logo from '../../../common/assets/img/logo.svg'
// Styles
import { useStyles } from "./headerStyles";
import Avatar from './Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import SideMenu from "../../sideMenu/SideMenu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";



const Header = ({ user, location, signOut, history }) => {
  const classes = useStyles();
  const theme = useTheme();
  // drawer
  const [open, setOpen] = useState(false)
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));



  return (
    <AppBar position="fixed" className={classes.header} elevation={0}>
      <div className={classes.header__inner}>
        {/* <Hidden xsDown>
          <img src={Logo} className={classes.header__logo} alt="logoo" />

          <div style={{ width: '70%', display: 'flex', marginLeft: 30 }} className={classes.header__tabs}>
            <Link to="/main" className={classes.tab} style={{ backgroundColor: `${location.pathname === '/main' && "rgba(0,0,0,.1)"}` }}>Home</Link>
            <Link to="/" className={classes.tab} style={{ backgroundColor: `${location.pathname === '/search' && "rgba(0,0,0,.1)"}` }}>Search</Link>
          </div> */}
        {/* <Avatar user={user} /> */}

        {/* </Hidden> */}
        {/* Mobile view */}
        <Hidden smUp>
          <MenuIcon style={{ color: "#000" }} onClick={() => setOpen(true)} />
          <Button className={classes.LogoutBtn} variant="outlined" onClick={() => signOut(() => history.push('/login'))}>Logout</Button>
        </Hidden>
        <Hidden xsDown>
          <div style={{ width: 200 }}>
            <Button className={classes.LoginBtn} variant="outlined" onClick={ () => history.push('/main')}>Home</Button>
            <Button className={classes.ProfileBtn} variant="outlined" onClick={() => history.push('/profile')}>Profile</Button>
          </div>
          <Typography className={classes.headerTitle}>Imaging Providers</Typography>
          <div style={{ width: 200 }}>
            <Button className={classes.LogoutBtn} variant="outlined" onClick={() => signOut(() => history.push('/login'))}>Logout</Button>
          </div>
        </Hidden>
      </div>

      {mobileView && <SideMenu open={open} handleClose={() => setOpen(false)} />}
    </AppBar>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, { signOut })(withRouter(Header));
