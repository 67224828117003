import axios from "axios";
import { API } from "../../common/constants";

// configure axios
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export const Login = async (credentials) => {
  const res = await axios.post(`${API}/auth/login`, credentials);
  localStorage.setItem("user", res.data);
  return res;
};

export const logOut = async () => {
  const session = localStorage.getItem("user");
  const res = await axios.get(`${API}/auth/logout`, {
    headers: {
      Authorization: `Token ${session}`,
    },
  });
  return res;
};

export const getCurrentUser = async () => {
  const session = localStorage.getItem("user");
  const res = await axios.get(`${API}/auth/current`, {
    headers: {
      Authorization: `Token ${session}`,
    },
  });

  return res.data;
};

export const forgotPw = async (email) => {
  const res = await axios.post(`${API}/auth/password/forget`, email);

  return res;
};

export const setPassword = async (data) => {
  const session = localStorage.getItem("user");
  const res = await axios.post(`${API}/password/set`, data, {
    headers: {
      Authorization: `Token ${session}`,
    },
  });
  return res;
};

export const resetPass = async (token, data) => {
  const res = await axios.put(`${API}/auth/password/reset?${token}`, data);
  return res;
};

export const checkToken = async (token, type) => {
  const res = await axios.get(`${API}/auth/check/token?${token}&type=${type}`);
  return res;
};

export const flag = async (id, body) => {
  const session = localStorage.getItem("user");
  const res = await axios.post(
    `${API}/website/error?id=${id}`,
    { message: body.body },
    {
      headers: {
      Authorization: `Token ${session}`,
      },
    }
  );
  return res;
};
