import React, { Suspense } from "react";
import { ThemeProvider } from "@material-ui/core";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./config/state/store";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
import Login from "./auth/components/login/Login";
import Forgot from "./auth/components/forgot/Forgot";
import { theme } from "./common/assets/jss/appStyles";
import App from "./app/";

import "react-toastify/dist/ReactToastify.css";
import "./common/assets/index.css";
import ResetPW from "./auth/components/reset/ResetPW";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/auth/password/reset/:token" component={ResetPW} />
            <Route path="/password/forgot" component={Forgot} />
            <Route path="/login" component={Login} />
            <Route path="/" component={App} />
          </Switch>
        </Suspense>
      </Provider>
      <ToastContainer hideProgressBar />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
