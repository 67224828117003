import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form'
import { useStyles } from './profileStyles';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { withRouter } from "react-router-dom";
import LockIcon from '@material-ui/icons/Lock';
import { Paper, Typography, Grid, Button, OutlinedInput, InputAdornment } from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import { updateInfo, updatingInfo, cleanInfoErrors } from "../../auth/state/actions";

const RenderInputField = ({ meta: { touched, invalid, error }, input, name, type, disabled, placeholder }) => {
    const classes = useStyles();

    const returnInputIcon = () => {
        if (type === "email") return <MailIcon />
        if (type === "text") return <PersonIcon />
        else return <LockIcon />
    }

    return (
        <OutlinedInput
            disabled={disabled}
            required
            type={type}
            className={classes.login__input}
            name={name}
            startAdornment={
                <InputAdornment position="start">
                    {returnInputIcon()}
                </InputAdornment>
            }
            placeholder={placeholder}
            helpertext={touched ? error : ""}
            error={touched && invalid}
            {...input}
        />
    )
}

const Profile = ({ handleSubmit, profileForm, updatingInfo, updateInfo, user, history, cleanInfoErrors }) => {
    const classes = useStyles();
    const submit = (values) => {
        updatingInfo();
        const reqData = {
            ...values,
            ...user
        };
        updateInfo(reqData, () => history.push("/"));
    }

    useEffect(() => {
        return () => {
            cleanInfoErrors();
        };
    }, [cleanInfoErrors]);


    return (
        <div className={classes.profile}>
            <Paper className={classes.profile__box}>
                <Typography variant="h5" align="center" style={{ marginBottom: 20 }}>Update Profile</Typography>
                <form onSubmit={handleSubmit(values => submit(values))}>
                    {/* <Grid container >
                        <Grid xs={6}>
                            <Field name="last_name" disabled type="text" component={RenderInputField} />
                        </Grid>
                        <Grid xs={6}>
                            <Field name="first_name" disabled type="text" component={RenderInputField} />
                        </Grid>
                        <Grid xs={12}>
                            <Field name="email" disabled type="email" component={RenderInputField} />
                        </Grid>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Field name="password" placeholder="New Password" type="password" component={RenderInputField} />
                    </Grid>

                    {/* <Grid xs={12}>
                        <Field name="password_confirm" placeholder="Confirm Password" type="password" component={RenderInputField} />
                    </Grid> */}
                    <Button fullWidth color="primary" type="submit" className={classes.profile__button}>Update Profile</Button>
                </form>
            </Paper>
        </div>
    );
}

const mapStateToProps = ({ auth, form, update }) => ({
    user: auth.user,
    profileForm: form,
    info: update
});

export default compose(reduxForm({ form: 'profile', enableReinitialize: true }), connect(mapStateToProps, { updateInfo, updatingInfo, cleanInfoErrors }))(withRouter(Profile));
