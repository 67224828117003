import { reducer as formReducer } from "redux-form";
import AuthReducer from "../../auth/state/reducer";
import updateReducer from "../../auth/state/updateReducer";
import flagReducer from "../../auth/state/flagReducer";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  form: formReducer,
  auth: AuthReducer,
  update: updateReducer,
  flag: flagReducer,
};
