/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form'
import { Link, withRouter, useLocation } from 'react-router-dom'
import { signIn, authRequest } from '../../state/actions'
import { OutlinedInput, InputAdornment, Button, CircularProgress, Slide, Typography } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import { toast } from "react-toastify";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// styles
import { useStyles } from './loginStyles'

const RenderInputField = ({ meta: { touched, invalid, error }, input, name, type, disabled, placeholder }) => {
    const classes = useStyles();
    const [enablePassword, setEnablePassword] = useState(false);

    return (
        <OutlinedInput
            disabled={disabled}
            required
            className={classes.login__input}
            name={name}
            type={type === "password" ? enablePassword === false ? "password" : "text" : "email"}
            placeholder={placeholder}
            startAdornment={
                <InputAdornment position="start">
                    {type === "email" ? <PersonIcon /> : <LockIcon />}
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="start">
                    {type === "password" ? enablePassword
                        ? <VisibilityIcon className={classes.viewPassword} onClick={() => setEnablePassword(!enablePassword)} />
                        : <VisibilityOffIcon className={classes.viewPassword} onClick={() => setEnablePassword(!enablePassword)} />
                        : ""}
                </InputAdornment>
            }
            helpertext={touched ? error : ""}
            error={touched && invalid}
            {...input}
        />
    )
}

const Login = ({ handleSubmit, signIn, history, auth, authRequest }) => {
    const classes = useStyles()
    const location = useLocation();

    useEffect(() => {
        if (location?.state?.reset === true) {
            toast.success("Password Reset Successfully", { autoClose: 5000 });
        }
        const session = localStorage.getItem("user");
        session && history.push("/")
    }, []);

    const submit = (values) => {
        authRequest()
        signIn(values, () => history.push('/'));
    }

    return (
        <div className={classes.login}>
            <Slide in direction="right">
                <div>
                    <div className={classes.login__box}>
                        <Typography variant="h5" className={classes.login__title}>
                            Log-in to your account
                        </Typography>
                        <div>
                            <form onSubmit={handleSubmit(values => submit(values))} className={classes.login__form}>
                                <Field type="email" name="email" placeholder="Email" component={RenderInputField} />
                                {/* <Field type="email" name="email" placeholder="Email" component={RenderInputField} onChange={(e, val) => handleEmailChange(val)}/> */}
                                {<Slide direction="right" in><Field type="password" name="password" placeholder="Password" component={RenderInputField} /></Slide>}
                                {/* {activated && <Slide direction="right" in><Field type="password" name="password" placeholder="Password" component={RenderInputField} /></Slide>} */}
                                <div className={classes.wrapper}>
                                    {/* <Button disabled={auth.loading} type="submit" fullWidth variant="contained" className={classes.login__submit}>{activated ? "Login" : "Next"}</Button> */}
                                    <Button disabled={auth.loading} type="submit" fullWidth variant="contained" className={classes.login__submit}>{"Login"}</Button>
                                    {auth.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>
                            {/* <Link className={classes.login__forgot} to="/password/forgot"><Typography variant="subtitle1">Forgot Password ?</Typography></Link> */}
                        </div>
                    </div>
                </div>
            </Slide>
        </div>
    );
}

const mapStateToProps = ({ auth }) => ({ auth })

export default compose(connect(mapStateToProps, { signIn, authRequest }), reduxForm({ form: 'login' }), withRouter)(Login);