import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from 'recompose';
import { withRouter } from "react-router-dom";
import { Grid, Tooltip } from "@material-ui/core";
import { useStyles } from "./mainStyles";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../common/constants";
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button/Button";
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { updateVisits, getUser, setFlagRequest, sendFlag } from "../../auth/state/actions"
import Fab from '@material-ui/core/Fab';
import FlagIcon from '@material-ui/icons/Flag';
import Flag from "../common/Flag";
import { toast } from 'react-toastify';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LanguageIcon from '@material-ui/icons/Language';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const Main = ({ user, updateVisits, getUser, setFlagRequest, sendFlag }) => {
  const classes = useStyles();
  const [webView, setWebView] = useState({});
  const [anchor, setAnchor] = useState(true);
  const [open, setOpen] = useState(false);
  const [flagId, setFlagId] = useState({});
  const [defaultArray, setDefaultArray] = useState([]);
  const [sortBy, setSortBy] = useState("user_num_times");
  const handleChange = (e) => {
    let target = e.target.value;
    setSortBy(target);
    if (target == "num_times" || target == "user_num_times") setDefaultArray(user?.websites?.sort((a, b) => (+b[target]) - (+a[target])));
    else if (target == "name") setDefaultArray(user?.websites?.sort((a, b) => a[target].toLowerCase() > b[target].toLowerCase() ? 1 : -1));
    else if (target == "created") setDefaultArray(user?.websites?.sort((a, b) => (+a[target]) - (+b[target])));
  }

  useEffect(() => {
    setDefaultArray(user.websites);
    return () => { }
  }, [])
  const handleInputView = (id) => {
    if (webView == id) setWebView({});
    else setWebView(id)
  }

  const visitFun = (web) => {
    window.open(web.url, "_blank");
    updateVisits(web.id).then(getUser());
  }

  const copyFunc = (web) => {
    navigator.clipboard.writeText(web.password)
    toast.success("Password Copied to Clipboard Succefully", { autoClose: 3000, })
  }

  const handleSendFlag = (body) => {
    setFlagRequest();
    const fallback = () => {
      toast.success("Flag Sent Succefully", { autoClose: 3000, })
      setOpen(false);
      setFlagId({});
    }
    sendFlag(flagId, body, fallback)
  }

  const handleFlagId = (id) => {
    setOpen(true);
    setFlagId(id);
  }

  return (
    <Grid container item justify="center" className={classes.mainGrid}>
      <Grid container item xs={11} md={8} style={{ position: "relative" }} >
        {/* <Grid item xs={12} className={classes.titleGrid} >
          <span className={classes.titleGridTypo}><FontAwesomeIcon className={classes.applicantsIcon} icon={faUsers} />PACs Providers</span>
        </Grid> */}
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Sort</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={sortBy}
            onChange={handleChange}
            label="Sort"
          >
            <MenuItem value={"user_num_times"}>No. Visits (current user)</MenuItem>
            <MenuItem value={"num_times"}>No. Visits</MenuItem>
            <MenuItem value={"name"}>Alphabetically</MenuItem>
            <MenuItem value={"created"}>Chronologically</MenuItem>
          </Select>
        </FormControl>
        {defaultArray.map((web) => {
          return (
            <Grid
              onClick={!anchor
                ? () => ""
                : () => visitFun(web)}
              key={web.id} item container xs={12} className={classes.siteMainGrid}>
              <Grid item container xs={6} md={3} >
                <img alt="" src={`${API}${web.image}`} href="logo" className={classes.img__logo} />
              </Grid>
              <Grid item container style={{ textAlign: "left" }} xs={12} md={5} direction="row">
                <Grid item xs={12} sm={2} md={12} className={classes.siteName} >{web.name}</Grid>
                <Grid item xs={12} sm={5} md={6} style={{ position: "relative" }} >
                  <PersonIcon size="small" className={classes.usernameIcon} />
                  <span className={classes.userNameText} >Username : {web.username}</span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={6}
                  onMouseEnter={() => setAnchor(false)}
                  onMouseLeave={() => setAnchor(true)}
                  onClick={() => handleInputView(web.id)}
                  style={{ position: "relative" }}
                >
                  {webView == web.id
                    ? <VisibilityIcon onClick={() => handleInputView(web.id)} size="small" className={classes.usernameIcon} />
                    : <VisibilityOffIcon onClick={() => handleInputView(web.id)} size="small" className={classes.usernameIcon} />}
                  <span className={classes.userNameText}>
                    Password :
                    {webView != web.id
                      ? web.password.replace(
                        new RegExp(
                          ".(?=.{0," +
                          (web.password.length - 1) +
                          "}$)",
                          "g"
                        ),
                        "*"
                      )
                      : web.password}
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} style={{ fontSize: 16, fontWeight: 400, position: "relative" }}
                justify="flex-end"
                alignItems="center">
                <Tooltip title="Copy Password" placement="top">
                    <Fab color="primary"
                            aria-label="copy"
                            size="small"
                            onClick={() => copyFunc(web)}
                            onMouseEnter={() => setAnchor(false)}
                            onMouseLeave={() => setAnchor(true)}
                            className={classes.copyIcon}>
                        <FileCopyIcon />
                    </Fab>
                </Tooltip>
                <Tooltip title="No. Visits" placement="top" className={classes.visitsCounter}>
                    <Badge badgeContent={web.num_times} color="secondary">
                        <LanguageIcon />
                    </Badge>
                </Tooltip>
                <Tooltip title="No. Visits (current user)" placement="top" className={classes.visitsCounter}>
                    <Badge badgeContent={web.user_num_times} color="secondary">
                        <PersonIcon />
                    </Badge>
                </Tooltip>
              </Grid>
              <Grid item container xs={6} md={1}
                justify="flex-end"
                alignItems="center"
                onClick={() => handleFlagId(web.id)}
                onMouseEnter={() => setAnchor(false)}
                onMouseLeave={() => setAnchor(true)}
              >
                <Tooltip title="break WebSite" placement="top" >
                  <Fab color="default" size="small" className={classes.flagButton}>
                    <FlagIcon size="small"  className={classes.flag} />
                  </Fab>
                </Tooltip>
                {open && <Flag open={open} setOpen={setOpen} save={handleSendFlag} />}
              </Grid>
            </Grid>);
        })}
      </Grid>
    </Grid >
  );
}

const mapStateToProps = ({ auth }) => ({ user: auth.user });

export default compose(connect(mapStateToProps, { updateVisits, getUser, setFlagRequest, sendFlag }))(withRouter(Main));
