import {
  Login,
  forgotPw,
  getCurrentUser,
  setPassword,
  resetPass,
  checkToken,
  logOut,
  flag,
} from "../services/authServices";
import Types from "../state/types";
import { toast } from "react-toastify";
import axios from "axios";
import { API } from "../../common/constants";

const handleError = (err, type, dispatch, showToast, warn) => {
  if (err.response) {
    if (err.response.status >= 500) {
      showToast && toast[warn ? "warn" : "error"]("Connection Error");
      dispatch({ type, payload: ["Connection Error"] });
    } else if (err.response.status >= 400) {
      showToast &&
        toast[warn ? "warn" : "error"](Object.values(err.response.data));
      dispatch({ type, payload: Object.values(err.response.data) });
    }
  } else {
    showToast && toast[warn ? "warn" : "error"]("Connection Error");
    dispatch({ type, payload: ["Connection Error"] });
  }
};

// *========> Website Visits <========*

export const updateVisits = (number) => async (dispatch) => {
  const session = localStorage.getItem("user");
  try {
    const { data } = await axios.get(`${API}/website/visit?id=${number}`, {
      headers: {
        Authorization: `Token ${session}`,
      },
    });
  } catch (err) {
    handleError(err, Types.UPDATE_ERROR, dispatch);
  }
};

// *========> Profile <========*
export const updatingInfo = () => ({ type: Types.UPDATE_REQUEST });
export const cleanInfoErrors = () => ({ type: Types.UPDATE_CLEAN });

export const updateInfo = (newInfo, redirect) => async (dispatch) => {
  const session = localStorage.getItem("user");

  try {
    const { data } = await axios.put(`${API}/user/profile`, newInfo, {
      headers: {
        Authorization: session,
      },
    });
    redirect && redirect();
  } catch (err) {
    handleError(err, Types.UPDATE_ERROR, dispatch);
  }
};

export const authRequest = () => ({ type: Types.AUTH_REQUEST });
export const setPwRequest = () => ({ type: Types.SET_PW_REQUEST });
export const cleanErrors = () => ({ type: Types.AUTH_CLEAN });

export const signIn = (values, redirect) => async (dispatch) => {
  try {
    const { data } = await Login(values);
    redirect();
  } catch (err) {
    if (err.response) {
      if (err.response.status >= 500) {
        dispatch({ type: Types.AUTH_ERROR });
        toast.error("unexpected error!");
      } else if (err.response.status >= 400) {
        // Toastify
        toast.warn(Object.values(err.response.data)[0][0], {
          autoClose: 5000,
        });
        dispatch({
          type: Types.AUTH_ERROR,
          payload: Object.values(err.response.data),
        });
      }
    } else if (err.request) {
      dispatch({ type: Types.AUTH_ERROR, payload: ["Connection Error"] });
      toast.error("Connection Error");
    }
  }
};

// Logout the user from the system
export const signOut = (redirect) => async (dispatch) => {
  try {
    const { data } = await logOut();
    localStorage.removeItem("user");
    redirect && redirect();
    dispatch({
      type: Types.AUTH_CLEAN,
      payload: false,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.status >= 500) {
        dispatch({
          type: Types.AUTH_ERROR,
          payload: ["Connection Error"],
        });
      } else if (err.response.status >= 400) {
        dispatch({
          type: Types.AUTH_ERROR,
          payload: Object.values(err.response.data),
        });
      }
    } else if (err.request) {
      dispatch({
        type: Types.AUTH_ERROR,
        payload: ["Connection Error"],
      });
    }
  }
};

export const forgot = (email, redirect) => async (dispatch) => {
  try {
    const { data } = await forgotPw(email);

    toast.success("Please check your Email", { autoClose: 5000 });
    redirect();

    dispatch({
      type: Types.AUTH_USER,
      payload: false,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.status >= 500) {
        toast.error("unexpected error", { autoClose: 5000 });
        dispatch({ type: Types.AUTH_ERROR, payload: ["unexpected error"] });
      } else if (err.response.status >= 400) {
        toast.error(Object.values(err.response.data)[0][0], {
          autoClose: 5000,
        });
        dispatch({
          type: Types.AUTH_ERROR,
          payload: Object.values(err.response.data)[0][0],
        });
      }
    } else if (err.request) {
      toast.error("Connection Error", { autoClose: 5000 });
      dispatch({ type: Types.AUTH_ERROR, payload: ["Connection Error"] });
    }
  }
};

export const checkSession = (token, type, callback, activateExpire) => async (
  dispatch
) => {
  try {
    const { data } = await checkToken(token, type);
    callback && callback();
  } catch (err) {
    if (err.response) {
      if (err.response.status >= 500) {
        toast.error("unexpected error", { autoClose: 5000 });
        dispatch({ type: Types.AUTH_ERROR, payload: ["unexpected error"] });
      } else if (err.response.status >= 400) {
        toast.error(Object.values(err.response.data)[0], { autoClose: 5000 });
        dispatch({
          type: Types.AUTH_ERROR,
          payload: Object.values(err.response.data)[0][0],
        });
      }
    } else if (err.request) {
      toast.error("Connection Error", { autoClose: 5000 });
      dispatch({ type: Types.AUTH_ERROR, payload: ["Connection Error"] });
    }
  }
};

export const resetPassword = (token, values, redirect) => async (dispatch) => {
  try {
    const { data } = await resetPass(token, values);
    redirect();
    dispatch({
      type: Types.AUTH_USER,
      payload: false,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.status >= 500) {
        toast.error("unexpected error", { autoClose: 5000 });
        dispatch({ type: Types.AUTH_ERROR, payload: ["unexpected error"] });
      } else if (err.response.status >= 400) {
        toast.error(Object.values(err.response.data)[0][0], {
          autoClose: 5000,
        });
        dispatch({
          type: Types.AUTH_ERROR,
          payload: Object.values(err.response.data)[0][0],
        });
      }
    } else if (err.request) {
      dispatch({
        type: Types.AUTH_ERROR,
        payload: ["Connection Error"],
      });
    }
  }
};

// get current user logged in
export const getUser = (redirect) => async (dispatch) => {
  try {
    const user = await getCurrentUser();

    dispatch({
      type: Types.AUTH_USER,
      payload: user,
    });
    return user;
  } catch (err) {
    if (err.response) {
      if (err.response.status >= 500) {
        dispatch({
          type: Types.AUTH_ERROR,
          payload: ["Connection Error"],
        });
      } else if (err.response.status === 403) {
        localStorage.removeItem("user");
        redirect && redirect();
      } else if (err.response.status >= 400) {
        dispatch({
          type: Types.AUTH_ERROR,
          payload: Object.values(err.response.data),
        });
      }
    } else {
      dispatch({
        type: Types.AUTH_ERROR,
        payload: ["Connection Error"],
      });
    }
  }
};

export const setPWFirstTime = (password) => async (dispatch) => {
  try {
    const { data } = await setPassword(password);

    dispatch({
      type: Types.AUTH_USER,
      payload: data,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.status >= 500) {
        dispatch({
          type: Types.SET_PW_ERROR,
          payload: ["Connection Error"],
        });
      } else if (err.response.status >= 400) {
        dispatch({
          type: Types.SET_PW_ERROR,
          payload: Object.values(err.response.data),
        });
      }
    } else {
      dispatch({
        type: Types.SET_PW_ERROR,
        payload: ["Connection Error"],
      });
    }
  }
};

export const sendFlag = (id, body, callback) => async (dispatch) => {
  try {
    const { data } = await flag(id, body);

    dispatch({
      type: Types.FLAG_SUCCESS,
      payload: data,
    });

    callback();
  } catch (err) {
    let toast = true;
    handleError(err, Types.FLAG_ERROR, dispatch, toast);
  }
};

export const setFlagRequest = () => {
  return {
    type: Types.FLAG_REQUEST,
  };
};
