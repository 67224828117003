// dimensions

export const filter = 260;
export const header = 35;
export const upperHeader = 40;
export const lowerHeader = 150;
// color
export const blueColor = "#2888d1";
export const darkBlueColor = "#1367a8";



export const maxWidth = "100%";
export const headerHeight = "65px";
export const drawerWidth = 260;
// ==> Colors
export const primaryColor = "#38c7d5";
export const primaryColorHover = "#1c9ca9";
export const secondaryGradientColor = "#7fe2ee";
export const tertiaryColor = "#333542";
export const darkTertiaryColor = "#22232c";
export const fontColor = "#1a1b39";
export const iconColor = "#3f51b5";
export const borderColor = "#9b9bff";
export const dangerColor = "#f33223";
export const successColor = "#449d48";
export const warnColor = "#CCA300 ";
export const whiteColor = "#ffffff";
export const lightGrayColor = "#f7f8fb";
export const blackColor = "#000";