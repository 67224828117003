import { makeStyles } from "@material-ui/core";
import { primaryColor } from "../../../common/assets/layout";

export const useStyles = makeStyles((theme) => ({
  login: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  login__box: {
    minWidth: "350px",
    maxWidth: "350px",
    minHeight: 400,
    position: "absolute",
    borderRadius: 8,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    backgroundColor: "#fff",
  },
  loginBox: {
    padding: 10,
    position: "relative",
  },
  login__title: {
    width: "100%",
    // height: 150,
    color:"#4f6d7a",
    marginBottom:20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  login__logo: {
    width: 170,
  },
  hrbreak: {
    width: "150px",
    margin: "40px auto 0",
    border: ".5px solid #e3e3e3",
  },
  login__form: {
    width: "100%",
    minHeight: 100,
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  login__input: {
    width: "100%",
    marginBottom: 20,
    height: 42,
    "& .MuiOutlinedInput-input":{
      padding:0,
      marginRight:10,
      width:"85%"
    },
  },
  login__submit: {
    // width: 150,
    backgroundColor: "#4f6d7a",
    color: "#FFF",
    textTransform: "capitalize",
    margin: "20px auto",
    "&:hover": {
      backgroundColor: "#4f6d7a",
    },
  },
  login__forgot: {
    display: "inline-block",
    "&:hover":{
      cursor:"pointer",
      color:"#4f6d7a"
    }
  },
  buttonProgress: {
    color: primaryColor,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    width: "100%",
    position: "relative",
  },
  forgot: {
    displat: "inline-block",
    margin: 10,
  },
  viewPassword:{
    cursor:"pointer"
  }
}));
