/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form'
import { Link, withRouter } from 'react-router-dom'
import { checkSession, authRequest, resetPassword } from '../../state/actions'
import { OutlinedInput, InputAdornment, Button, CircularProgress, Slide, Typography } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
// styles
import { useStyles } from '../login/loginStyles'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const RenderInputField = ({ meta: { touched, invalid, error }, input, name, type, disabled, placeholder, classes }) => {
    const [enablePassword, setEnablePassword] = useState(false);
    return (
        <OutlinedInput
            disabled={disabled}
            required
            className={classes.login__input}
            name={name}
            type={type === "password" ? enablePassword === false ? "password" : "text" : "email"}
            placeholder={placeholder}
            startAdornment={
                <InputAdornment position="start">
                    {type === "email" ? <PersonIcon /> : <LockIcon />}
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="start">
                    {type === "password" ? enablePassword
                        ? <VisibilityIcon className={classes.viewPassword} onClick={() => setEnablePassword(!enablePassword)} />
                        : <VisibilityOffIcon className={classes.viewPassword} onClick={() => setEnablePassword(!enablePassword)} />
                        : ""}
                </InputAdornment>
            }
            helpertext={touched ? error : ""}
            error={touched && invalid}
            {...input}
        />
    )
}

const ResetPW = ({ handleSubmit, history, match, auth, authRequest, checkSession, resetPassword }) => {
    const classes = useStyles()
    const [invalidSession, setInvalidSession] = useState(true)
    const { token } = match.params

    useEffect(() => {
        // if theres any user redirect back to main root
        const session = localStorage.getItem("user")
        session && history.push("/")
        // check the validity of current session
        checkSession(token, "reset", () => setInvalidSession(false));
    }, [checkSession, history, token])

    const submit = (values) => {
        authRequest();
        resetPassword(token, values, () => { history.push({ pathname: "/login", state: { reset: true } }); });
    }

    return (
        <div className={classes.login}>
            <Slide in direction="right">
                <div>
                    <div className={classes.login__box}>
                        <div className={classes.login__title}>
                            Reset Password
                        </div>
                        <div>
                            <form onSubmit={handleSubmit(values => submit(values))} className={classes.login__form}>
                                <Field disabled={invalidSession} classes={classes} type="password" name="password1" placeholder="Password" component={RenderInputField} />
                                <Field disabled={invalidSession} classes={classes} type="password" name="password2" placeholder="Confirm Password" component={RenderInputField} />

                                <div className={classes.wrapper}>
                                    <Button disabled={invalidSession || auth.loading} type="submit" fullWidth variant="contained" className={classes.login__submit}>Confirm</Button>
                                    {auth.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>

                            <Link className={classes.login__forgot} to="/login"><Typography variant="subtitle1">Back to Login</Typography></Link>
                        </div>
                    </div>
                </div>
            </Slide>
        </div>
    );
}

const mapStateToProps = ({ auth }) => ({ auth })

export default compose(connect(mapStateToProps, { checkSession, authRequest, resetPassword }), reduxForm({ form: 'reset' }), withRouter)(ResetPW);