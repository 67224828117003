import React, { useEffect, Suspense } from "react";
import { connect } from "react-redux";
import App from "./App";
import { getUser } from "../auth/state/actions";
import Loading from "./common/loading";
// import Login from "../auth/components/login/Login";

const AppContainer = ({ auth, getUser, history }) => {
  const session = localStorage.getItem("user");

  useEffect(() => {
    session && getUser(() => history.push("/"));
  }, []);

  const renderApp = () => {
    return auth.user ? <App /> : <Loading errors={auth.error} />;
  };

  return (
    <>
      {session ? (
        renderApp()
      ) : (
        <Suspense fallback={<Loading errors={["Loading..."]} />}>
          {!window.location.origin.includes("login") && history.push("/login")}
        </Suspense>
      )}
    </>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });
export default connect(mapStateToProps, { getUser })(AppContainer);
