import React, { Suspense } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
// Models
import Main from './Main';
import Header from "./common/header";
// import Footer from "./common/footer";
// Styles
import '../common/assets/App.css';
import SessionTimeouts from "./session-timeout";
import Profile from "./profile/Profile";

function App() {

  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <div style={{ height: 175, width: '100%' }}></div>
        <Switch>
          <Route path="/profile" component={Profile} />
          <Route path="/main" component={Main} />
          <Redirect from="/" to="/main" />
        </Switch>
        {/* <Footer /> */}
        <SessionTimeouts />
      </Suspense>
    </div>
  );
}

export default withRouter(App);
