import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import MuiTextField from "./MuiTextField";

const useStyles = makeStyles((theme) => ({
    dialog: {
        minWidth: 250,
        minHeight: 250,
        textAlign: 'center'
    },
    dialog__title: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        margin: 25,
        fontWeight: 600,
        "&:hover": {
            color: "#ec4646"
        }
    },
    dialog__icon: {
        marginRight: 10
    },
    dialog__content: {
        margin: 10,
        minWidth: 450,
        [theme.breakpoints.down('xs')]: {
            minWidth: 280
        }
    },
    dialog__action: {
        justifyContent: "space-around",
        marginBottom: 10
    },
    dialog__btn: {
        backgroundColor: "red",
        textTransform: "capitalize",
        color: "#FFF",
        "&:hover": {
            backgroundColor: "#911e15",
        },
    }
}))

const Email = ({ handleSubmit, open, save, setOpen, flag }) => {
    const classes = useStyles()
    const submit = (values) => {
        save(values)
    }
    return (
        <Dialog open={open} onClose={() => setOpen(false)} className={classes.dialog} aria-labelledby="form-dialog-title" >
            <form onSubmit={handleSubmit((values) => submit(values))}>
                <div className={classes.dialog__title} id="form-dialog-title">
                    {/* <AlternateEmailIcon className={classes.dialog__icon} />  */}
                    Message Varun if any issues / or if password has been changed
                </div>
                <DialogContent className={classes.dialog__content}>
                    <Field
                        name="body"
                        required
                        rows={5}
                        multiline
                        component={MuiTextField}
                    />
                </DialogContent>
                <DialogActions className={classes.dialog__action}>
                    {/* <Button disabled={flag.settingFlag} className={classes.dialog__btn} onClick={() => setOpen(false)} variant="contained" >
                        Close
                    </Button> */}
                    <Button disabled={flag.settingFlag} startIcon={<MailIcon />} style={{ backgroundColor: "#4f6d7a", color: "#FFF" }} type="submit" variant="contained" >
                        Send
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

const mapStateToProps = ({ flag }) => ({ flag: flag });

export default compose(
    reduxForm({ form: 'emailApplicant', destroyOnUnmount: false }),
    connect(mapStateToProps)
)
    (Email);
