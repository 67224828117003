import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    profile: {
        width: '100%',
        position: 'relative'
    },
    profile__box: {
        maxWidth: 550,
        padding: 20,
        margin: '20px auto',
        boxSizing: 'border-box'
    },
    profile__input: {
        width: '100%',
        marginBottom: 20
    },
    profile__button: {
        backgroundColor: '#43606c',
        color: '#fff',
        textTransform: 'capitalize',
        margin: "20px auto",
        '&:hover': {
            backgroundColor: '#1e4252'
        }
    },
    login__input: {
        width: "100%",
        marginBottom: 20,
        height: 42,
        "& .MuiOutlinedInput-input":{
          padding:0,
          marginRight:10,
          width:"85%"
        },
      },
}))