
import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import clsx from 'clsx';
import { signOut } from '../../auth/state/actions'
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import { Divider, Hidden } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../../common/assets/img/logo.svg'

const useStyles = makeStyles({
  list: {
    width: 250,
    textAlign: 'left',
    backgroundColor: "#4f6d7a",
    color: '#fff',
    height: '100%'

  },
  fullList: {
    width: 'auto',
  },
  // drawer
  drawerHeader: {
    width: 100,
  },
  header_listItem: {
    padding: '0px 10px',
    "&:hover": {
      backgroundColor: "#fff"
    },
  },
  sidemenu: {
    backgroundColor: "#4f6d7a"
},
sidemenu__logo: {
    backgroundColor: "#4f6d7a",
    height: 55
}

});

function SideMenuDrawer({ open, handleClose, user, signOut, history }) {
  const classes = useStyles();
  let items = [{ label: "Profile", link: "/profile" }, { label: "Home", link: "/home" }];

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    handleClose(false);
  };

  const list = () => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer()} onKeyDown={toggleDrawer()} >
      <List>
        <Link to="/">
          <ListItem button className={classes.sidemenu__logo}>
            <img src={logo} alt="logo" className={classes.drawerHeader} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>

        <Hidden lgUp>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <Link to={item.link}>
                <ListItem button>
                  <ListItemText>{item.label}</ListItemText>
                </ListItem>
              </Link>
              <Divider />
            </React.Fragment>
          ))}
        </Hidden>

        {/* <ListItem button onClick={() => console.log("Sign In")}>
          <ListItemText>Sign In</ListItemText>
        </ListItem>
        <Divider /> */}

        <Divider />

      </List>
    </div >
  );

  return (
    <div style={{ backgroundColor: "#4f6d7a" }}>
      <React.Fragment>
        <SwipeableDrawer
          anchor='left'
          style={{ zIndex: 9999999 }}
          open={open}
          onClose={toggleDrawer()}
          onOpen={toggleDrawer()}
        >
          {list('left')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

const mapStateTopProps = ({ auth }) => ({ user: auth.user })

export default compose(connect(mapStateTopProps, { signOut }), withRouter)(SideMenuDrawer)