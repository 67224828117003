const AUTH_USER = "AUTH_USER";
const AUTH_ERROR = "AUTH_ERROR";
const AUTH_CLEAN = "AUTH_CLEAN";
const AUTH_REQUEST = "AUTH_REQUEST";
const AUTH_ACTIVE = "AUTH_ACTIVE";

const SET_PW_ERROR = "set_pw_error";
const SET_PW_REQUEST = "set_pw_request";

const UPDATE_REQUEST = "update_request";
const UPDATE_CLEAN = "update_clean";
const UPDATE_ERROR = "update_error";
const UPDATE_SUCCESS = "update_success";

const FLAG_ERROR = "flag_error";
const FLAG_SUCCESS = "flag_success";
const FLAG_REQUEST = "flag_request";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AUTH_USER,
  AUTH_ERROR,
  AUTH_CLEAN,
  AUTH_REQUEST,
  AUTH_ACTIVE,
  SET_PW_ERROR,
  SET_PW_REQUEST,
  UPDATE_REQUEST,
  UPDATE_CLEAN,
  UPDATE_ERROR,
  UPDATE_SUCCESS,
  FLAG_ERROR,
  FLAG_SUCCESS,
  FLAG_REQUEST,
};
