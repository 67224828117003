import React, { useState, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./session-timeout-dialog";
import { signOut } from "../auth/state/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const SessionTimeouts = ({ user, signOut, history }) => {
  const [timeoutModalOpen, setTimeOutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeOutCountDown] = useState(0);
  const [timeOut, setTimeOut] = useState(300000);
  const [countdownInterval, setCountDownInterval] = useState(null);
  let countDown = 10;

  useEffect(() => {
    return () => {
      setCountDownInterval(clearInterval(countdownInterval));
    };
  }, []);

  const handleLogout = async () => {
    try {
      setTimeOutModalOpen(false);
      setCountDownInterval(clearInterval(countdownInterval));
      setTimeOut(300000);
      signOut(() => history.push("/login")).then(() =>
        window.location.reload()
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleContinue = () => {
    setTimeOutModalOpen(false);
    setCountDownInterval(clearInterval(countdownInterval));
    clearInterval(countdownInterval);
    setCountDownInterval(null);
    setTimeOut(300000);
  };

  const onActiveFunc = () => {
    if (!timeoutModalOpen) {
      setTimeOut(300000);
    }
  };

  const onIdleFunc = () => {
    if (user && !timeoutModalOpen) {
      setTimeOutModalOpen(true);
      setTimeOutCountDown(countDown);
      setCountDownInterval(
        setInterval(() => {
          if (countDown > 0) {
            setTimeOutCountDown(--countDown);
          } else {
            handleLogout();
          }
        }, 1000)
      );
    }
  };

  return (
    <React.Fragment>
      <IdleTimer
        onActive={onActiveFunc}
        onIdle={onIdleFunc}
        timeout={timeOut}
      />
      {timeoutModalOpen && (
        <SessionTimeoutDialog
          countdown={timeoutCountdown}
          onContinue={() => handleContinue()}
          onLogout={() => handleLogout()}
          open={timeoutModalOpen}
          setClose={() => setTimeOutModalOpen(false)}
          setOpen={() => setTimeOutModalOpen(true)}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, { signOut })(
  withRouter(SessionTimeouts)
);
