import React from "react";
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./headerStyles";
import { signOut } from '../../../auth/state/actions'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Popover from '@material-ui/core/Popover';

const Avatar = ({ user, signOut, history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div >
      <div className={classes.avatar}>
        <Typography onClick={handleClick} align="center">
          logged in as {user.username}
        </Typography>

        <PersonOutlineIcon onClick={handleClick} />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ margin: "25px 0px 0px 10px" }}
        >
          <List style={{ width: 140 }}>
            <Link to="/profile">
              <ListItem button>
                <ListItemText >
                  <Typography style={{ fontSize: "14px" }} align="center">Profile</Typography>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to="/history">
              <ListItem button>
                <ListItemText >
                  <Typography style={{ fontSize: "14px" }} align="center">History</Typography>
                </ListItemText>
              </ListItem>
            </Link>
            <Divider />
            <ListItem button onClick={() => signOut(() => history.push('/login'))}>
              <ListItemText >
                <Typography style={{ fontSize: "14px" }} align="center">Sign Out</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Popover>
      </div>
    </div>
  );
};

const mapStateTopProps = ({ auth }) => ({ user: auth.user })

export default compose(connect(mapStateTopProps, { signOut }), withRouter)(Avatar)