import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainGrid: {
    paddingBottom: 250,
  },
  titleGrid: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid rgb(0,0,0,0.3)",
    lineHeight: "0.1em",
    margin: "10px 0 50px",
  },
  titleGridTypo: {
    backgroundColor: "#fff",
    padding: "20px 10px 0px 10px",
    fontWeight: 600,
    fontSize: 18,
  },
  applicantsIcon: {
    opacity: 0.5,
    margin: "0px 5px 0px 0px",
  },
  img__logo: {
    width: 150,
    height: 50,
    cursor: "pointer",
  },
  siteMainGrid: {
    minHeight: 70,
    borderBottom: "1px solid rgb(0,0,0,0.3)",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e3e3e3",
    },
    [theme.breakpoints.down("sm")]: {
      height: 210,
    },
    [theme.breakpoints.down("xs")]: {
      height: 275,
    },
  },
  siteName: {
    fontSize: 24,
    marginBottom: 5,
    textTransform: "capitalize",
  },
  userNameIcon: {
    position: "absolute",
    top: 10,
  },
  userNameText: {
    position: "absolute",
    left: 30,
  },
  flag: {
    color: "rgb(252, 28, 3,0.7)",
    "&:hover": {
      color: "rgb(252, 28, 3,1)",
    },
  },
  flagButton: {
    marginTop: -15
  },
  visits: {
    position: "absolute",
    left: 30,
    top: 10,
  },
  copyIcon: {
    fontSize: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    position: "absolute",
    top: -70,
    left: 5,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  visitsCounter: {
    marginLeft: 10,
    marginTop: 4,
    '& .MuiBadge-badge': {
      top: '7px',
      right: '13px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 45,
    }
  }
}));
