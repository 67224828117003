/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form'
import { Link, withRouter } from 'react-router-dom'
import { forgot, authRequest } from '../../state/actions'
import { OutlinedInput, InputAdornment, Button, CircularProgress, Typography, Slide } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
// styles
import { useStyles } from '../login/loginStyles'

const renderInputField = ({ meta: { touched, invalid, error }, input, name, type, disabled, placeholder, classes }) => (
    <OutlinedInput
        disabled={disabled}
        required
        className={classes.login__input}
        name={name}
        type={type}
        placeholder={placeholder}
        startAdornment={
            <InputAdornment position="start">
                {type === "email" ? <PersonIcon /> : <LockIcon />}
            </InputAdornment>
        }
        helpertext={touched ? error : ""}
        error={touched && invalid}
        {...input}
    />
)

const Forgot = ({ handleSubmit, forgot, history, auth, authRequest }) => {
    const classes = useStyles()

    useEffect(() => {
        // if theres any user redirect back to main root
        const session = localStorage.getItem("user")
        session && history.push("/")
    }, [])

    const submit = (values) => {
        authRequest()
        forgot(values, () => history.push('/login'))
    }

    return (
        <div className={classes.login}>
            <Slide in direction="left">
                <div>
                    <div className={classes.login__box}>
                           <Typography variant="h5" className={classes.login__title}>
                                Forget Password
                            </Typography>
                        <div>
                            <form onSubmit={handleSubmit(values => submit(values))} className={classes.login__form}>
                                <Field type="email" name="email" classes={classes} placeholder="Email" component={renderInputField} />

                                <div className={classes.wrapper}>
                                    <Button disabled={auth.loading} type="submit" fullWidth variant="contained" className={classes.login__submit}>Send Email</Button>
                                    {auth.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>

                            <Link className={classes.login__forgot} to="/login"><Typography variant="subtitle1">Back to Login</Typography></Link>
                        </div>
                    </div>
                </div>
            </Slide>
        </div>
    );
}

const mapStateToProps = ({ auth }) => ({ auth })

export default compose(connect(mapStateToProps, { forgot, authRequest }), reduxForm({ form: 'forgot' }), withRouter)(Forgot);