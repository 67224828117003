import React from "react";
import { makeStyles } from "@material-ui/core";
import { primaryColor } from "../../../common/assets/layout";
import Loader from "react-loader-spinner";

const useStyles = makeStyles(() => ({
  loading: {
    width: "100%",
    height: "100vh",
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: primaryColor,
  },
}));

const Loading = ({ errors = [] }) => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <div className={classes.spinner}>
        <Loader type="Oval" color={primaryColor} height={80} width={80} />
        {errors.map((err) => {
          return err;
        })}
      </div>
    </div>
  );
};

export default Loading;
