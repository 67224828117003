import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    height: 70,
    backgroundColor: "#FFF",
    padding: "0px 20px",
  },
  headerTitle: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 30,
    letterSpacing: 3,
  },
  header__inner: {
    maxWidth: 1100,
    padding: "15px 0",
    margin: "auto",
    width: "100%",
    maxHeight: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    borderBottom: "3px solid rgb(0,0,0,1)",
  },
  header__logo: {
    width: 80,
  },
  avatar: {
    display: "flex",
    cursor: "pointer",
  },
  tab: {
    display: "inline-block",
    height: 70,
    width: 75,
    margin: "75px 10px 0",
    boxSizing: "border-box",
  },
  LogoutBtn: {
    color: "#FFF",
    backgroundColor: "#ec4646",
    "&:hover": { backgroundColor: "#ec4646" },
  },
  LoginBtn: {
    color: "#FFF",
    backgroundColor: "#4f6d7a",
    "&:hover": { backgroundColor: "#4f6d7a" },
  },
  ProfileBtn: {
    color: "#FFF",
    backgroundColor: "#4f6d7a",
    marginLeft: 5,
    "&:hover": { backgroundColor: "#4f6d7a" },
  },
}));
