import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PanToolIcon from "@material-ui/icons/PanTool";
import { Typography } from "@material-ui/core";

export const SessionTimeoutDialog = ({
  open,
  setClose,
  countdown,
  onLogout,
  onContinue,
  setOpen,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ margin: "auto", textAlign: "center" }}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "#4f6d7a" }}>
          <PanToolIcon size="small" style={{ marginRight: "20px" }} />
          <Typography>Warning</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The current session is about to expire in{" "}
            <span style={{ color: "red" }}>{countdown}</span> seconds. Would you
            like to continue the session?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: "center", display: "flex" }}>
          <Button
            onClick={() => {
              setClose();
              onLogout();
            }}
            disableRipple
            color="secondary"
            style={{ flex: 1 }}
          >
            <CancelIcon />
            <Typography>Logout</Typography>
          </Button>
          <Button
            onClick={() => {
              setClose();
              onContinue();
            }}
            disableRipple
            color="primary"
            style={{ flex: 1 }}
          >
            <CheckCircleIcon />
            <Typography>Continue Session</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SessionTimeoutDialog;
